import { FormattedMessage } from 'react-intl';
import { IconTool } from '@tabler/icons';

const icons = {
    IconTool
};

const other = {
    id: 'menu-item-vendors',
    type: 'group',
    children: [
        {
            id: 'vendor',
            title: <FormattedMessage id="Vendors" />,
            type: 'item',
            url: '/vendor',
            icon: icons.IconTool,
            external: false,
            target: false,
            breadcrumbs: false
        }
    ]
};

export default other;
