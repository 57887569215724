import { createContext, useEffect, useState } from 'react';

import GLCodeService from 'services/glcode.service';
import PropTypes from 'prop-types';
import PropertyService from 'services/property.service';
import useAuth from 'hooks/useAuth';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const [loadRequestToggle, setLoadRequestToggle] = useState(true);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [glCodeData, setGlCodeData] = useState([]);
    const [propertyData, setPropertyData] = useState([]);
    const [messageFlag, setMessageFlag] = useState(false);
    const [refresh, setRefresh] = useState(false);
    // let stompClient = null;
    const [message, setMessage] = useState('');

    const { isAuthenticated } = useAuth();

    /* const onMessageReceived = (payload) => {
        const payloadData = JSON.parse(payload.body);
        setMessage(payloadData);
        setMessageFlag(true);
    };


    const onConnected = () => {
        stompClient.subscribe('/feed/notification', onMessageReceived);
    };

    const connect = () => {
        const Sock = new SockJS('https://service.staging.vcmhmc.com:8080/ws');
        stompClient = over(Sock);
        stompClient.connect({}, onConnected, onError);
    }; */

    const getGlCode = () => {
        GLCodeService.getAllGLCodes().then((response) => {
            setGlCodeData(response);
        });
    };

    const getProperty = () => {
        PropertyService.getAllProperties().then((response) => {
            setPropertyData(response.data);
        });
    };

    useEffect(() => {
        if (isAuthenticated) {
            getGlCode();
            getProperty();
        }
    }, [isAuthenticated]);

    return (
        <DataContext.Provider
            value={{
                loadRequestToggle,
                setLoadRequestToggle,
                showProgressBar,
                setShowProgressBar,
                message,
                setMessage,
                messageFlag,
                setMessageFlag,
                glCodeData,
                refresh,
                setRefresh,
                propertyData
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

DataProvider.propTypes = {
    children: PropTypes.any
};

export default DataContext;
